<template>
    <div class="oxo-console-page">
        <h2 class="c-page-title">
            {{ $t('Account.Title.Expense') }}
        </h2>
        <div class="oxo-list" v-loading="loading">
            <div class="oxo-table">
                <el-table
                        :data="tableData"
                        empty-text="-"
                        default-expand-all
                        style="width: 100%">
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH1')"
                            width="80">
                        <template slot-scope="scope">
                            {{ scope.row.billing_cycle }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH2')">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.product_name }} / {{ scope.row.app_name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH3')"
                            width="140">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.subscription_type }} / {{ scope.row.expense_type }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH4')"
                            width="135">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.created_at }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH5')"
                            width="110">
                        <template slot-scope="scope">
                            {{ scope.row.amount }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH6')"
                            width="110">
                        <template slot-scope="scope">
                            <span style="color: #222">{{ scope.row.payment_amount }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH7')"
                            width="70">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.status }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH8')"
                            width="145">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.expense_no }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.ExpenseTable.TH9')"
                            width="145">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.order_no }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="oxo-table-pagination">
                <el-pagination
                        @current-change="listPageCurrentChange"
                        background
                        layout="prev, pager, next"
                        :page-size="pageSize"
                        :total="pageTotal">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Expense",
        data () {
            return {
                pageSize: 10,
                currentPage: 1,
                pageTotal: 0,
                loading: true,
                tableData: [],
            }
        },
        methods: {
            loadList() {
                this.loading = true
                this.$axios.get('/expense/list?pageSize='+this.pageSize+'&currentPage='+this.currentPage)
                    .then(res => {
                        this.pageTotal = res.total
                        this.tableData = []
                        if (res.list.length > 0) {
                            res.list.forEach((val) => {

                                this.tableData.push({
                                    billing_cycle: val.billing_cycle,
                                    product_name: val.product_name,
                                    app_name: val.app_name,
                                    amount: val.amount,
                                    payment_amount: val.payment_amount,
                                    created_at: val.created_at,
                                    expense_no: val.expense_no,
                                    expense_type: val.expense_type,
                                    order_no: val.order_no,
                                    paid_at: val.paid_at,
                                    status: val.status,
                                    subscription_type: val.subscription_type,
                                })
                            })
                        }
                        this.loading = false
                    }, err => {})
            },
            listPageCurrentChange(page) {

                this.currentPage = page
                this.loadList()
            },
        },
        created() {
            this.loadList()
        }
    }
</script>

<style scoped>

</style>