<template>
    <div class="oxo-console-page">
        <h2 class="c-page-title">
            {{ $t('Order.Title.List') }}
        </h2>
        <div class="oxo-list" v-loading="loading">
            <div class="oxo-table">
                <el-table
                        :data="tableData"
                        :empty-text="tableEmptyText"
                        default-expand-all
                        style="width: 100%">
                    <el-table-column
                            :label="$t('Order.ListTable.TH1')"
                            width="170">
                        <template slot-scope="scope">
                            {{ scope.row.orderNo }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Order.ListTable.TH2')">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.type }} / {{ scope.row.appName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Order.ListTable.TH3')"
                            width="135">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.createdAt }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Order.ListTable.TH4')"
                            width="135">
                        <template slot-scope="scope">
                            <span style="font-size: 12px">{{ scope.row.paidAt }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Order.ListTable.TH5')"
                            width="120">
                        <template slot-scope="scope">
                            {{ scope.row.amount }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Order.ListTable.TH6')"
                            width="120">
                        <template slot-scope="scope">
                            <span style="color: #222">{{ scope.row.paymentAmount }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Order.ListTable.TH7')"
                            width="70">
                        <template slot-scope="scope">
                            <span>{{ scope.row.statusName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Order.ListTable.TH8')"
                            width="145">
                        <template slot-scope="scope">
                            <span>
                                <router-link :to="'order/detail/'+scope.row.orderNo" class="oxo-link primary">
                                    {{ $t('Order.ListTable.LinkDetail') }}
                                </router-link>
                            </span>
                            <span v-if="scope.row.status === 10">
                                <em class="list-item-action-split"></em>
                                <router-link :to="'/console/order/pay/'+scope.row.orderNo" class="oxo-link warning">
                                    {{ $t('Order.ListTable.LinkPay') }}
                                </router-link>
                                <em class="list-item-action-split"></em>
                                <span>
                                    <a href="javascript:" class="oxo-link info" @click="cancelConfirm(scope.row.orderNo)" style="font-weight: normal" slot="reference">
                                        {{ $t('Order.ListTable.BtnCancel') }}
                                    </a>
                                </span>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="oxo-table-pagination">
                <el-pagination
                        @current-change="listPageCurrentChange"
                        background
                        layout="prev, pager, next"
                        :page-size="pageSize"
                        :total="pageTotal">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Order",
        data () {
            return {
                pageSize: 10,
                currentPage: 1,
                pageTotal: 0,
                loading: true,
                tableEmptyText: this.$t('Hint.Loading'),
                tableData: [],
            }
        },
        methods: {
            loadList() {
                this.loading = true
                this.$axios.get('/order/list?pageSize='+this.pageSize+'&currentPage='+this.currentPage)
                    .then(res => {
                        this.pageTotal = res.total
                        this.tableData = []
                        if (res.list.length > 0) {
                            res.list.forEach((val) => {

                                this.tableData.push({
                                    cancelVisible: false,
                                    orderNo: val.orderNo,
                                    productName: val.productName,
                                    appName: val.appName,
                                    type: val.type,
                                    status: val.status,
                                    statusName: val.statusName,
                                    amount: val.amount,
                                    paymentAmount: val.paymentAmount,
                                    paidAt: val.paidAt,
                                    createdAt: val.createdAt,
                                })
                            })
                        } else {
                            this.tableEmptyText = this.$t('Order.Hint.S1')
                        }
                        this.loading = false
                    }, err => {})
            },
            listPageCurrentChange(page) {

                this.currentPage = page
                this.loadList()
            },
            cancelConfirm(orderNo) {
                this.$confirm(this.$t('Order.Hint.S2'), this.$t('Order.Title.Dialog', { no: orderNo }), {
                    confirmButtonText: this.$t('Action.Confirm'),
                    cancelButtonText: this.$t('Action.Cancel'),
                    type: 'warning'
                }).then(() => {
                    this.cancelOrder(orderNo)
                }).catch(() => {});
            },
            cancelOrder(orderNo) {
                this.loading = true
                this.$axios.post('/order/cancel', {
                    orderNo: orderNo
                })
                    .then(res => {
                        this.$message.success(this.$t('Order.Hint.S3'))
                        this.loadList()
                    }, err => {})
            },
        },
        created() {
            this.loadList()
        }
    }
</script>

<style>

</style>