<template>
    <div class="oxo-console-page">
        <h2 class="c-page-title">
            <el-page-header @back="goBack(orderNo)" :title="$t('Navigation.GoBack')" :content="$t('Account.Title.Pay')"></el-page-header>
        </h2>
        <div style="margin: 8px 0" v-loading="loading">
            <el-form label-position="left" status-icon label-width="100px">
                <el-form-item :label="$t('Account.Payment.H1')">
                    <span style="font-weight: 500;font-size: 16px">{{ orderNo }}</span>
                </el-form-item>
                <el-form-item :label="$t('Account.Payment.H2')">
                    <span>{{ type }}</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>{{ productName }}</span>
                    <el-divider direction="vertical"></el-divider>
                    <span>{{ appName }}</span>
                </el-form-item>
                <el-form-item :label="$t('Account.Payment.H3')">
                    <span style="font-size: 20px;color: #f40">{{ paymentAmount }}</span>
                </el-form-item>
            </el-form>
            <div v-if="paid" style="font-size: 28px;text-align: center;margin-top: 28px">
                <i class="el-icon-success" style="color: #67C23A;margin-right: 12px;top: 2px;position: relative;"></i>
                <span>{{ $t('Account.Payment.Hint.PaySuccess') }}</span>
                <div>
                    <el-button @click="goBack(orderNo)" type="text">{{ $t('Navigation.GoBack') }}</el-button>
                </div>
            </div>
            <div v-else v-loading="qrCodeLoading">
                <el-form style="margin-top: 28px" label-position="left" status-icon label-width="100px">
                    <el-form-item :label="$t('Account.Payment.H4')">
                        <div class="oxo-radio-multiline">
                            <el-radio-group v-model="payment" @change="getQRCode">
<!--                                <el-radio label="AliPay" border>-->
<!--                                    <div class="aliPay"></div>-->
<!--                                </el-radio>-->
                                <el-radio label="WeChatPay" border>
                                    <div class="weChatPay"></div>
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>
                </el-form>
                <el-divider>{{ hint }} <span style="font-size: 16px;color: #f40">{{ finalPaymentAmount }}</span></el-divider>
                <div v-if="QRCode" style="width: 140px;height: 140px;border: 1px solid #eee;margin: 0 auto;cursor: pointer" @click="getQRCode">
                    <img :src="qrCodeImg" style="width: 100%;" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderPay",
        inject: ['reload'],
        data () {
            return {
                loading: true,
                qrCodeLoading: false,
                QRCode: false,
                paid: false,
                autoStatus: null,
                qrCodeImg: '',
                payment: 'WeChatPay',
                hint: this.$t('Account.Payment.Hint.Ready'),
                finalPaymentAmount: '',
                orderNo: this.$route.params.orderNo,
                item: '',
                paymentAmount: '',
                type: '',
                productName: '',
                appName: '',
            }
        },
        methods: {
            goBack(orderNo) {
                this.$router.push({path: '/console/order/detail/'+orderNo})
            },
            getQRCode() {

                if (this.autoStatus) clearInterval(this.autoStatus)

                this.hint = 'Loading...'
                this.qrCodeImg = ''
                this.finalPaymentAmount = ''
                this.QRCode = true
                this.qrCodeLoading = true
                this.$axios.post('/order/pay/qrCode', {
                    orderNo: this.orderNo,
                    payment: this.payment,
                }).then(res => {
                    this.qrCodeImg = res.qrCode
                    this.qrCodeLoading = false
                    this.finalPaymentAmount = this.paymentAmount
                    this.hint = this.$t('Account.Payment.Hint.'+this.payment)
                    this.paymentStatus()
                }, error => {})
            },
            paymentStatus() {

                if (this.autoStatus) clearInterval(this.autoStatus)

                this.$axios.post('/payment/status', {
                    'out_trade_no': this.orderNo
                })
                    .then(res => {

                        this.paid = res.paid
                        this.paymentStatusInterval()

                    }, error => {
                        this.paymentStatusInterval()
                    })
            },
            paymentStatusInterval() {
                if (! this.paid) {
                    this.autoStatus = setInterval(() => {
                        this.paymentStatus()
                    }, 3000)
                }

                this.$once('hook:beforeDestroy', () => {
                    clearInterval(this.autoStatus)
                })
            }
        },
        created() {
            this.$axios.get('/order/pay/'+this.orderNo)
                .then(res => {

                    // this.item = res.item
                    this.paid = res.paid
                    this.paymentAmount = res.paymentAmount
                    this.type = res.type
                    this.productName = res.productName
                    this.appName = res.appName
                    this.loading = false

                    this.getQRCode()

                }, error => {})
        }
    }
</script>

<style scoped>
    .el-form-item {
        margin-bottom: 4px;
        width: 47%;
        display: inline-block;
    }
    .oxo-radio-multiline .el-radio {
        padding: 10px 12px;
        width: 160px;
        text-align: center;
    }
    .aliPay {
        width: 132px;
        height: 32px;
        background: url('../../../assets/images/alipay.svg') no-repeat center;
    }
    .weChatPay {
        width: 132px;
        height: 32px;
        background: url('../../../assets/images/wechat_pay.svg') no-repeat center;
    }
</style>