<template>
    <div class="oxo-console-page">
        <h2 class="c-page-title">
            {{ $t('Account.Title.Transaction') }}
        </h2>
        <div class="oxo-list" v-loading="loading">
            <div class="oxo-table">
                <el-table
                        :data="tableData"
                        empty-text="-"
                        default-expand-all
                        size="mini"
                        style="width: 100%">
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH1')"
                            width="100">
                        <template slot-scope="scope">
                            {{ scope.row.billing_cycle }}
                            <p style="margin: 0">{{ scope.row.type }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH2')"
                            width="150">
                        <template slot-scope="scope">
                            {{ scope.row.transaction_no }}
                            <p style="margin: 0">{{ scope.row.created_at }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH9')"
                            width="80">
                        <template slot-scope="scope">
                            {{ scope.row.fund_type }} {{ scope.row.transaction_type }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH4')"
                            width="140">
                        <template slot-scope="scope">
                            {{ scope.row.order_no }}
                            <p style="margin: 0">{{ scope.row.expense_no }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH5')">
                        <template slot-scope="scope">
                            {{ scope.row.transaction_channel }}
                            <p style="margin: 0">{{ scope.row.transaction_channel_sn }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH6')"
                            width="100">
                        <template slot-scope="scope">
                            <span style="font-size: 14px">{{ scope.row.amount }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH10')"
                            width="100">
                        <template slot-scope="scope">
                            <span :style="'font-size: 14px;' + (scope.row.account_change > 0 ? 'color:#F56C6C' : '')">
                                {{ scope.row.account_change > 0?'+':'' }}{{ scope.row.account_change.toFixed(2) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH7')"
                            width="100">
                        <template slot-scope="scope">
                            <span style="font-size: 14px">{{ scope.row.balance }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            :label="$t('Account.TransactionTable.TH8')"
                            width="100">
                        <template slot-scope="scope">
                            {{ scope.row.comment }}
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="oxo-table-pagination">
                <el-pagination
                        @current-change="listPageCurrentChange"
                        background
                        layout="prev, pager, next"
                        :page-size="pageSize"
                        :total="pageTotal">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Expense",
        data () {
            return {
                pageSize: 10,
                currentPage: 1,
                pageTotal: 0,
                loading: true,
                tableData: [],
            }
        },
        methods: {
            loadList() {
                this.loading = true
                this.$axios.get('/transaction/list?pageSize='+this.pageSize+'&currentPage='+this.currentPage)
                    .then(res => {
                        this.pageTotal = res.total
                        this.tableData = []
                        if (res.list.length > 0) {
                            res.list.forEach((val) => {

                                this.tableData.push({

                                    transaction_no: val.transaction_no,
                                    expense_no: val.expense_no,
                                    order_no: val.order_no,
                                    amount: val.amount,
                                    account_change: val.account_change,
                                    comment: val.comment,
                                    balance: val.balance,
                                    type: val.type,
                                    transaction_type: val.transaction_type,
                                    transaction_channel: val.transaction_channel,
                                    transaction_channel_sn: val.transaction_channel_sn,
                                    fund_type: val.fund_type,
                                    created_at: val.created_at,
                                    billing_cycle: val.billing_cycle,
                                })
                            })
                        }
                        this.loading = false
                    }, err => {})
            },
            listPageCurrentChange(page) {

                this.currentPage = page
                this.loadList()
            },
        },
        created() {
            this.loadList()
        }
    }
</script>

<style scoped>

</style>