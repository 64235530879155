<template>
    <div class="oxo-console-page">
        <h2 class="c-page-title">
            <el-page-header @back="goBack" :title="$t('Navigation.GoBack')" :content="$t('Order.Title.Detail')"></el-page-header>
        </h2>
        <div v-loading="loading">
            <el-card class="box-card">
                <div class="text item">
                    {{ $t('Order.Detail.H1') }}
                    <span>{{ order.no }}</span>
                </div>
                <div class="text item">
                    {{ $t('Order.Detail.H3') }}
                    <span>{{ order.createdAt }}</span>
                </div>
                <div class="text item">
                    {{ $t('Order.Detail.H2') }}
                    <span>{{ order.type }}</span>
                </div>
                <div class="text item">
                    {{ $t('Order.Detail.H4') }}
                    <span>{{ order.paidAt }}</span>
                </div>
                <div class="text item">
                    {{ $t('Order.Detail.H6') }}
                    <span>{{ order.amount }}</span>
                </div>
                <div class="text item">
                    {{ $t('Order.Detail.H5') }}
                    <span>{{ order.statusName }}</span>
                </div>
                <div class="text item">
                    {{ $t('Order.Detail.H7') }}
                    <span style="color: #f40">{{ order.paymentAmount }}</span>
                </div>
                <div v-if="order.status === 10" style="margin-top: 12px;">
                    <el-button type="warning" @click="payOrder(order.no)">{{ $t('Order.Detail.BtnPay') }}</el-button>
                    <el-button style="margin-left: 20px" @click="cancelConfirm(order.no)" slot="reference">
                        {{ $t('Order.Detail.BtnCancel') }}
                    </el-button>
                </div>
                <div v-else-if="order.status === 20" style="margin-top: 12px;text-align: center">
                    <router-link class="oxo-link primary" :to="order.route">{{ $t('Order.Detail.LinkToApp') }}</router-link>
                </div>
            </el-card>
            <div class="oxo-list" style="margin-top: 24px">
                <div class="oxo-table">
                    <el-table
                            :data="tableData"
                            empty-text="-"
                            default-expand-all
                            style="width: 100%">
                        <el-table-column
                                :label="$t('Order.Detail.TH1')"
                                width="200">
                            <template slot-scope="scope">
                                <div style="">{{ scope.row.productName }} / {{ scope.row.appName }}</div>
                                <div style="">{{ scope.row.appAlias }}</div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                :label="$t('Order.Detail.TH2')"
                                width="140">
                            <template slot-scope="scope">
                                {{ scope.row.billingType }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                :label="$t('Order.Detail.TH3')"
                                width="180">
                            <template slot-scope="scope">
                                {{ scope.row.duration }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                :label="$t('Order.Detail.TH4')">
                            <template slot-scope="scope">
                                <div :key="item" v-for="item in scope.row.items">
                                    {{ item }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                                :label="$t('Order.Detail.TH5')"
                                width="100">
                            <template slot-scope="scope">
                                {{ scope.row.quantity }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                :label="$t('Order.Detail.TH6')"
                                width="150">
                            <template slot-scope="scope">
                                <span style="color: #222">{{ scope.row.amount }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderDetail",
        inject: ['reload'],
        data () {
            return {
                cancelVisible: false,
                order: {
                    no: this.$route.params.orderNo,
                    type: '',
                    createdAt: '',
                    paidAt: '',
                    status: 0,
                    statusName: '',
                    amount: '',
                    paymentAmount: '',
                    route: '',
                },
                loading: true,
                tableData: [],
            }
        },
        methods: {
            goBack() {
                this.$router.push({path: '/console/order'})
            },
            cancelConfirm(orderNo) {
                this.$confirm(this.$t('Order.Hint.S2'), this.$t('Order.Title.Dialog', { no: orderNo }), {
                    confirmButtonText: this.$t('Action.Confirm'),
                    cancelButtonText: this.$t('Action.Cancel'),
                    type: 'warning'
                }).then(() => {
                    this.cancelOrder(orderNo)
                }).catch(() => {});
            },
            cancelOrder(orderNo) {
                this.loading = true
                this.$axios.post('/order/cancel', {
                    orderNo: orderNo
                })
                    .then(res => {
                        this.$message.success(this.$t('Order.Hint.S3'))
                        this.reload()
                    }, err => {})
            },
            payOrder(orderNo) {
                this.$router.push({path: '/console/order/pay/'+orderNo})
            }
        },
        created() {
            this.$axios.get('/order/detail/'+this.order.no)
                .then(res => {

                    this.order.type = res.order.type
                    this.order.createdAt = res.order.createdAt
                    this.order.paidAt = res.order.paidAt
                    this.order.status = res.order.status
                    this.order.statusName = res.order.statusName
                    this.order.amount = res.order.amount
                    this.order.paymentAmount = res.order.paymentAmount
                    this.order.route = '/console'+res.order.route

                    this.tableData = []
                    if (res.details.length > 0) {
                        res.details.forEach((val) => {
                            this.tableData.push({
                                amount: val.amount,
                                appAlias: val.appAlias,
                                appName: val.appName,
                                billingType: val.billingType,
                                duration: val.duration,
                                productName: val.productName,
                                paymentAmount: val.paymentAmount,
                                paidAt: val.paidAt,
                                quantity: val.quantity,
                                items: val.items,
                            })
                        })
                    }

                    this.loading = false
                }, err => {})
        }
    }
</script>

<style scoped>
    .item {
        padding: 6px 0;
        width: 50%;
        display: inline-block;
    }
    .item span {
        margin-left: 20px;
    }
</style>